import React from 'react';
import Header4 from '../Common/Header4';
import OurProject4 from '../Elements/OurProject4';
import Footer from '../Common/Footer';

class projects extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <OurProject4 />
                <Footer />
            </>
        );
    };
};

export default projects;