import React from 'react';
import { NavLink } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

var img1 = require('./../../images/attic.jpg');

class OurValue2 extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <>
                        <iframe title="vimeo-player" src="https://player.vimeo.com/video/751888343?h=17b15ee279" width="100%" height="360" frameborder="0" allowfullscreen></iframe>

                        <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                            <div className="video-section-inner">
                                                <div className="video-section-content">
                                                    <ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='748247670' onClose={() => this.setState({ isOpen: false })} />
                                                    <div className="video-section-left">
                                                        <NavLink to={"#"} className="play-now" onClick={this.openModal}>
                                                            <i className="icon fa fa-play" />
                                                            <span className="ripple" />
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </>
        );
    }
};

export default OurValue2;
