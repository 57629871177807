import React from 'react';
import Header4 from '../Common/Header4';
import Footer from '../Common/Footer';

class comingsoon extends React.Component {
    render() {
        return (
            <>
                <Header4 />
            <div className='coming-soon'>COMING SOON</div>
                <Footer />
            </>
        );
    };
};

export default comingsoon;