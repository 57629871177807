import React from 'react';
import GoogleMaps from "simple-react-google-maps"
import Header from './../Common/Header4';
import Footer from './../Common/Footer';

class ContactUs extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-12 contact-us">
                                            <div className="contact-one m-b30">
                                                {/* TITLE START */}
                                                <div className="section-head">
                                                    <div className="mt-separator-outer separator-middle">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>(619) 716-2765</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>info@jthandymaninc.com</p>
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gmap-outline">
                                <GoogleMaps
                                apiKey={"AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0"}
                                style={{height: "400px", width: "100%"}}
                                zoom={12}
                                center={{lat: 32.7157, lng: -117.1611}}
                                markers={{lat: 32.7157, lng: -117.1611}} //optional
                                />
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default ContactUs;
