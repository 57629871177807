import React from 'react';
import Header from './../Common/Header4';
import Footer from './../Common/Footer';
import AboutCompany from '../Elements/AboutCompany';
import Specialization3 from '../Elements/Specialization3';
import OurServices4 from '../Elements/OurServices4';

class Services extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <AboutCompany />
                    <Specialization3 />
                    <OurServices4 />
                <Footer />
                    
                </div>

                
               

            </>
        );
    };
};

export default Services;
