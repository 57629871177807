import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
    require('./../../images/gallery/portrait/jt-gallery-1.jpg'),
    require('./../../images/gallery/portrait/jt-gallery-2.jpg'),
    require('./../../images/gallery/portrait/jt-gallery-3.jpg'),
    require('./../../images/gallery/portrait/jt-gallery-4.jpg'),
    require('./../../images/gallery/portrait/jt-gallery-5.jpg'),

]

var bnr1 = require('./../../images/background/line.png');

class About3 extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    render() {
        const options = {
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            margin:30,
            nav:false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            items:1,
            dots: true
        };
        return (
            <>
                <div className="section-full p-t30 p-b80 bg-white inner-page-padding">
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-md-5 col-sm-6">
                                    <div className="m-about ">
                                        <OwlCarousel className="owl-carousel about-us-carousel" {...options}>
                                            {images.map((item, index) => (
                                                <div className="item" key={index}>
                                                    <div className="ow-img">
                                                    <NavLink to="/contactus"><img src={item.default} alt=""/></NavLink>
                                                    </div>
                                                </div>
                                            ))}
                                        
                                        </OwlCarousel>
                                        
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-6">
                                    <div className="m-about-containt text-black p-t80">
                                        <div className="m-about-years bg-moving" style={{backgroundImage:"url(" + bnr1.default + ")"}}>
                                            <span className="text-primary large-title">25+</span>
                                            <span className="large-title-info">Years of Experience</span>
                                        </div>
                                        <h3 className="font-weight-600">About us</h3>
                                        <p>JT Handyman Inc is intended to focus on restoring family’s home since they are a family driving business dad son and brother and employees that are like family we thrive in fixing anything wrong with your old home and making a new home for a new or old family whatever your dream we can build it. Myself the owner I started this business since 2019 with the hope to help others just like the help was given to me in a time of need I bare the scars of my past on me but look towards the hope of the future with my company in mind and making it possible for others to have the home they dreamed of or the best service anyone can offer please trust in us with your home and dreams you won’t be disappointed.</p>
                                        <div className="author-info p-t20">
                                            <div className="author-signature">
                                                <img src={require('./../../images/Signature+Black.png').default} alt="Signature" width={150} />
                                            </div>
                                            <div className="author-name">
                                                <h4 className="m-t0">Jonathan Torres</h4>
                                                <p>CEO &amp; Founder</p>
                                            </div>
                                            <NavLink to="/contactus" className="site-button btn-effect m-b15"><span>Get in touch</span></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About3;
