import React from 'react';
import { NavLink } from 'react-router-dom';

const teamMembers = [
	{
		image: require('./../../images/our-team5/ceo.png'),
		membername: 'Jonathan Torres',
		position: 'CEO & Founder',
		bio: 'I started working at a young age and fell in love with construction in spite of my past I\'ve gathered myself and made something that keeps me happy at the same time making other peoples dream come true.',
	},
	{
		image: require('./../../images/our-team5/brian-torres.png'),
		membername: 'Bryan Torres',
		position: 'Laborer',
		bio: 'I been working with jt since the age of 15 as a laborer. Today I am proud to say I am one of the lead tech specialist in drywall and framing today I am 19 and I can honestly say jt will care of u just like they did with me.',
	},
	{
		image: require('./../../images/our-team5/hernan-cordova.png'),
		membername: 'Hernan Cordova',
		position: 'Plumber & Finishing Tech',
		bio: 'Hernan has several years of experience in various areas such as handyman services plumbing construction and is a top professional as our lead finishing technician. Bio coming soon!',
	},
	{
		image: require('./../../images/our-team5/valdemir-torres.png'),
		membername: 'Valdemir Torres',
		position: 'Project Manager',
		bio: 'I am jt project manager but honestly I treat every employee and customer like my family it\'s no wonder they call me pops. I have over 30 yrs in the industry but out of the whole time JT is who I call family.',
	},
	{
		image: require('./../../images/our-team5/jesus-escobar.png'),
		membername: 'Webby Escobar',
		position: 'Head Of Web Development',
		bio: 'My name is Jesus aka Webby been doing webmastery for over 15+ years and counting with focus on front-end web. Love what I do and treat clients needs and business like my own. ',
	},
	{
		image: require('./../../images/our-team5/steve-vigil.png'),
		membername: 'Steve Vigil',
		position: 'Partner & Estimator',
		bio: 'I started as a demolition expert years ago now focused on sales, marketing and lead project cost estimations. I have over 20+ years of experience in building brands.',
	}
]

class Team2 extends React.Component {
	render() {
		return (
			<>
			<div className="section-full p-t80 p-b50 bg-white inner-page-padding">
			<div className="container">
			{/* TITLE START */}
			<div className="section-head">
			<div className="mt-separator-outer separator-center">
			<div className="mt-separator">
			<h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Team</h2>
			</div>
			</div>
			</div>
			{/* TITLE END */}
			{/* IMAGE Team START */}
			<div className="section-content">
			<div className="row">
			{teamMembers.map((item, index) => (
				<div key={index} className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc m-b30   ">
				<div className="our-team-1 hover-animation-1">
				<div className="profile-image scale-in-center"><img src={item.image.default} alt=""/></div>
				<div className="figcaption">
				<h4>{item.membername}</h4>
				<h5>{item.position}</h5>
				<p>{item.bio}</p>
				<div className="icons">
				<NavLink to={"#"} ><i className="fa fa-facebook" /></NavLink>
				<NavLink to={"#"} > <i className="fa fa-twitter" /></NavLink>
				<NavLink to={"#"} > <i className="fa fa-instagram" /></NavLink>
				<NavLink to={"#"} ><i className="fa fa-rss" /></NavLink>
				<NavLink to={"#"} > <i className="fa fa-youtube" /></NavLink>
				<NavLink to={"#"} > <i className="fa fa-linkedin" /></NavLink>
				</div>
				</div>
				</div>
				</div>
			))}
			</div>
			<section className='cousin'>
			<img src="/cousins.jpg" alt="" className='cousin__img'/>
			<div className="cousin__content">
			<h2 className='cousin__content-title'>Our partnership with CNS</h2>
			<p className='cousin__content-text'>jt and his cousin have been around the business since young kids with both of them following on the footsteps of other family members they are teaming up to better serve customers and to be able to use both of their construction knowledge and with Carlos leading the demo department we try to do everything in order to have our customers only deal with us and be able to see the same people through the whole job instead of having random people around your home we understand how it can be an inconvenience for some people to have alot of different faces in your home, this partnership facilitates that with Carlos and myself Sharing the same goals better serving our customers and building their dream home.</p>
	 		<img src="/speedy.png" alt="" className='cousin__content-img'/>
			</div>
			</section>   
			</div>
			</div>
			</div>
			</>
		);
	}
};

export default Team2;
