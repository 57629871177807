import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/s-1.png').default} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">In our work we have pride, quality is what we provide.</h3>
                                            <p>Our finished products are always a sight our customers are amazed everytime its a great feeling to finish a job and have a customer smile the whole time we have been known to take many projects that seems to hard for other we make possible.</p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Call JT Handyman For a Quote:</span>
                                                    <h4>(619) 716 2765</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <a href="https://calendly.com/jthandymaninc" className="site-button-secondry btn-effect">Make An Appointment</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;
