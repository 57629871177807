import React from 'react';
import Header from './../Common/Header4';
import Footer from './../Common/Footer';
import About3 from './../Elements/About3';
import Specialization3 from './../Elements/Specialization3';
import Team2 from './../Elements/Team2';

class About extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <About3 />
                    <Specialization3 />
                    <Team2 />

                </div>

                <Footer />

            </>
        );
    };
};

export default About;
