import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{backgroundImage:"url(" + bnr1.default + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                                <h3 className="m-t0">Serving San Diego & Inland Empire</h3>
                                <p>We specialize in drywall, flooring & tile installation services.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/1.jpg').default}  alt=""/>
                                        <div className="figcaption">
                                            <h4>Drywall & Plaster</h4>
                                            <p>Install drywall panels to wood or metal studs along with any plastering.</p>
                                            <NavLink to=""><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/2.jpg').default} alt="" />
                                        <div className="figcaption">
                                            <h4>Flooring services</h4>
                                            <p>Ensuring that the flooring materials are well-positioned, secure, and clean. </p>
                                            <NavLink to=""><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/3.jpg').default} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Tile Installation</h4>
                                            <p>Determine the tile or marble pattern layout, cut and shape the tiles to size.</p>
                                            <NavLink to=""><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization3;
